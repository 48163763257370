import React from "react";
import Layout from "@layouts/RecrutamentoLayout";

const RecrutamentoPage = () => {
  return (
      <Layout title="Headhunter" servico="HEADHUNTER"> 
        <div className="text-xl flex flex-col text-gray-700 space-y-4 mr-10">
            <div className="w-full flex flex-row my-10">        
                <img className="h-14" src="/images/logo_escura.png" alt="Shelfin"/>
            </div> 
            <p className="font-medium text-gray-700 text-3xl mb-6">
                Contratar o Profissional certo é olhar o futuro de sua empresa!
            </p>
            <p>
                Nosso processo de <b>atração e seleção</b> de executivos é realizado por profissional com experiência de mais de 20 anos contratando para empresas nacionais e multinacionais. 
            </p>
            <p>
                Como <b>Headhunter</b> nosso trabalho de busca ativa de candidatos é estruturado em todas as etapas do processo, desde o levantamento do perfil dos talentos até a seleção feita pela empresa.
            </p>
            <p>
                Nosso Método de hunting vai além das informações disponibilizadas no currículo dos talentos, <b>buscando competências e habilidades específicas</b> para agregar valor à organização.   
            </p> 
        </div> 
      </Layout>
  )
}

export default RecrutamentoPage
